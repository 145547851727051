import * as React from 'react';
import tw from 'twin.macro';

const Outwrapper = tw.div`relative z-10 max-w-screen-lg xl:max-w-screen-xl mx-auto py-4 sm:mt-10 mt-3`;
const InnerWrapper = tw.div`px-4 sm:px-6 md:px-8 mb-2 sm:mb-20 xl:mb-8`;
const InnerWrapperNoVpadding = tw.div`px-4 sm:px-6 md:px-8`;

const Container = ({ children, noVPadding }) => {
    const InnerWrapperComponent = noVPadding ? InnerWrapperNoVpadding : InnerWrapper;
    return (
        <Outwrapper>
            <InnerWrapperComponent>
                {children}
            </InnerWrapperComponent>
        </Outwrapper>
    );
};

export default Container;
