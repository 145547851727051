import React from 'react';
import { Link } from 'gatsby';
import { withI18n } from '@lingui/react';

import { addLocaleUrl } from '../utils/url';

const pat = /^https?:\/\//i;

const LocalizedLink = ({ to, i18n, ...props }) => {
    if (pat.test(to)) {
        return <Link {...props} to={to} target="_blank" />;
    }
    return <Link {...props} to={addLocaleUrl(i18n.locale, to)} />;
};

export default withI18n()(LocalizedLink);
