import * as React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { withI18n } from '@lingui/react';
import tw from 'twin.macro';
import { Link } from 'gatsby';

import { localesMappging } from '../../utils';
import { addLocaleUrl } from '../../utils/url';

const A = ({ active, children, to }) => (
    <Link to={to} css={[tw`hover:bg-gray-50 block px-4 py-2 text-sm text-gray-700`, active ? tw`bg-gray-100 text-gray-900` : tw`text-gray-700`]}>{children}</Link>
);

const LocaleDropdown = ({ i18n }) => (
    <Menu as="div" tw="relative inline-block text-left">
        {({ open }) => (
            <>
                <div>
                    <Menu.Button tw="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                        {localesMappging[i18n.locale]}
                        <ChevronDownIcon tw="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                </div>

                <Transition
                    show={open}
                    as={React.Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        static
                        tw="origin-top-right absolute border-gray-200 border right-0 mt-2 w-28 rounded-md shadow-lg bg-white ring-1 ring-black focus:outline-none"
                    >
                        <div tw="py-1">
                            {Object.keys(localesMappging).map((locale) => (
                                <Menu.Item>
                                    {({ active }) => (
                                        <A
                                            to={addLocaleUrl(locale, '/')}
                                            active={active}
                                        >
                                            {localesMappging[locale]}
                                        </A>
                                    )}
                                </Menu.Item>

                            ))}
                        </div>
                    </Menu.Items>
                </Transition>
            </>
        )}
    </Menu>

);

export default withI18n()(LocaleDropdown);
