import * as React from 'react';
import tw from 'twin.macro';
import { Trans } from '@lingui/macro';
import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

import logoImage from '../../../static/img/logo.svg';
import Link from '../Link';
import LocaleDropdown from '../LocaleDropdown';
import { appLoginUrl, docUrl } from '../../config';

const HeaderLink = tw(Link)`mr-3 hover:text-gray-700 md:mr-5`;
const DropdownLink = tw(Link)`block px-3 py-2 rounded-md text-base font-medium text-black hover:bg-gray-700 hover:text-white text-center`;

const Header = () => (
    <Disclosure as="nav">
        {({ open }) => (
            <>
                <div tw="max-w-7xl mx-auto px-4 sm:px-6">
                    <div tw="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
                        <div tw="flex justify-start lg:w-0 lg:flex-1">
                            <Link to="/">
                                <img tw="h-8 w-auto sm:h-10 inline-block" src={logoImage} alt="" />
                            </Link>
                        </div>

                        <div tw="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                            <nav tw="hidden md:flex">
                                <HeaderLink to={docUrl}>
                                    <Trans>
                                        Docs
                                    </Trans>
                                </HeaderLink>
                                <HeaderLink to="/blog">
                                    <Trans>
                                        Blog
                                    </Trans>
                                </HeaderLink>
                            </nav>
                            <LocaleDropdown />
                            <HeaderLink to={appLoginUrl} tw="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:text-white bg-blue-700 hover:bg-blue-600">
                                <Trans>
                                    Sign In
                                </Trans>
                            </HeaderLink>
                        </div>
                        <div tw="sm:hidden">
                            <Disclosure.Button tw="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                <span tw="sr-only">Open main menu</span>
                                {open ? (
                                    <XIcon tw="block h-6 w-6" aria-hidden="true" />
                                ) : (
                                    <MenuIcon tw="block h-6 w-6" aria-hidden="true" />
                                )}
                            </Disclosure.Button>
                        </div>
                    </div>
                </div>

                <Disclosure.Panel tw="sm:hidden">
                    <div tw="px-2 pt-2 pb-3 space-y-1 bg-gray-200 mb-6">
                        <div tw="text-center">
                            <LocaleDropdown />
                        </div>
                        <DropdownLink to={docUrl}>
                            <Trans>
                                Docs
                            </Trans>
                        </DropdownLink>
                        <DropdownLink to={appLoginUrl}>
                            <Trans>
                                Log In
                            </Trans>
                        </DropdownLink>
                    </div>
                </Disclosure.Panel>

            </>
        )}
    </Disclosure>

);

export default Header;
