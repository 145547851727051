import React from 'react';
import tw from 'twin.macro';
import { Trans } from '@lingui/macro';
import Link from '../Link';
import { docUrl } from '../../config';

const Wrapper = tw.footer`text-center sm:pt-8 md:pt-8 xl:pt-8 sm:pb-8`;

const Footer = () => (
    <Wrapper>
        <div aria-label="footer" tw="focus:outline-none mx-auto container flex flex-col items-center justify-center">
            <div tw="text-black flex flex-col md:items-center pt-3">
                <div tw="my-6 text-base">
                    <ul tw="md:flex items-center">
                        <li tw="md:mr-6 cursor-pointer pt-4 lg:py-0">
                            <Link to="/blog" tw="focus:outline-none focus:underline hover:text-gray-500">
                                <Trans>
                                    Blog
                                </Trans>
                            </Link>
                        </li>
                        <li tw="md:mr-6 cursor-pointer pt-4 lg:py-0">
                            <Link to={docUrl} tw="focus:outline-none focus:underline hover:text-gray-500">
                                <Trans>
                                    Docs
                                </Trans>
                            </Link>
                        </li>
                        <li tw="md:mr-6 cursor-pointer pt-4 lg:py-0">
                            <Link to="/privacy" tw="focus:outline-none focus:underline hover:text-gray-500">
                                <Trans>
                                    Privacy Policy
                                </Trans>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div tw="text-sm mb-10">
                    <p tw="focus:outline-none">© 2021 moin.bz All rights reserved</p>
                </div>
            </div>
        </div>
    </Wrapper>
);

export default Footer;
